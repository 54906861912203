import React from 'react'
import {useOnEvent} from 'react-app-events'
import Button from '@components/V3/Button'
import {useLoginViaAuthWindow} from '@helpers/auth/loginViaAuth'
import useUserId from '@hooks/useUserId'
import {useTranslation} from 'next-i18next'

import LoggedIn from '../LoggedIn'
import useOnLogin from '../useOnLogin'

export default function ButtonLogin(props) {
  const loginViaAuth = useLoginViaAuthWindow()
  const onLogin = useOnLogin()
  const {t} = useTranslation('auth')
  const userId = useUserId()

  useOnEvent('saveSession', async () => {
    debugger
    if (onLogin) {
      await onLogin()
    }
  })

  if (userId) return <LoggedIn />

  return (
    <Button big full onClick={loginViaAuth} primary>
      {t('logIn')}
    </Button>
  )
}
