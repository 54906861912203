import React from 'react'
import Button from '@components/Button'
import logout from '@helpers/auth/logout'
import {NextRouter, withRouter} from 'next/router'
import {TFunction, withTranslation} from 'next-i18next'

import styles from './styles.module.css'

export interface LogoutProps {
  t: TFunction
  router: NextRouter
}

export class Logout extends React.Component<LogoutProps> {
  async logout() {
    await logout()
  }

  componentDidMount() {
    const to = this.props.router.query.to
    if (to) {
      this.props.router.replace(Array.isArray(to) ? to[0] : to)
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <p>{this.props.t('youAreLoggedIn')}</p>
        <Button onClick={() => this.props.router.replace('/')}>{this.props.t('goHome')}</Button>
        <Button onClick={this.logout} danger>
          {this.props.t('signOut')}
        </Button>
      </div>
    )
  }
}

export default withTranslation('auth')(withRouter<LogoutProps>(Logout))
