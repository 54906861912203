import isMainDomain from '@helpers/misc/domain/isMainDomain'
import {useRouter} from 'next/router'

export default function useOnLogin() {
  const router = useRouter()
  return () => {
    if (router.query.to) {
      router.replace(Array.isArray(router.query.to) ? router.query.to[0] : router.query.to)
      return
    }

    if (isMainDomain()) {
      router.replace('/')
      return
    }

    router.replace('/pedir')
  }
}
