import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import LoadingText from '@components/LoadingText'
import {websiteLogo} from '@data/fragments/website/__generated__/websiteLogo'
import {getWebsiteAuthInfo_config} from '@data/queries/website/__generated__/getWebsiteAuthInfo'
import getDeviceId from '@helpers/auth/getDeviceId'
import getPathname from '@helpers/router/getPathname'
import useLogo from '@hooks/useLogo'
import justoAccountURL from '@providers/justoAccountURL'
import Link from 'next/link'
import {useRouter} from 'next/router'

import BackgroundPhoto from './BackgroundPhoto'

import styles from './styles.module.css'

function MainLogo() {
  const logoUrl = useLogo({darkMode: true})
  return (
    <a className={styles.mainLogoContainer} href="https://getjusto.com" target="blank">
      <img src={logoUrl} alt="Logo" className={styles.mainLogo} />
    </a>
  )
}

function Logo(props: {website: websiteLogo}) {
  const {website} = props
  const logoUrl = useLogo({darkMode: true})
  const src = website && website.logo ? website.logo.url : logoUrl
  return (
    <div className={styles.logoContainer}>
      <Link href="/" passHref legacyBehavior>
        <img className={styles.logo} src={src} alt="Logo" />
      </Link>
    </div>
  );
}

export default function AuthLayout(props: {
  children: React.ReactNode
  website: websiteLogo
  websiteId: string
  config: getWebsiteAuthInfo_config
}) {
  const {children, website, websiteId, config} = props
  const router = useRouter()

  const renderMainLogo = () => {
    if (!website) return
    const justoAccountLoginURL = `${justoAccountURL}/recover?deviceId=${getDeviceId()}&websiteId=${websiteId}&url=${encodeURIComponent(
      getPathname(router) + router.query
    )}`
    return (
      <>
        <MainLogo />
        <a href={justoAccountLoginURL} style={{opacity: 0}}>
          .
        </a>
      </>
    )
  }

  const renderPhoto = () => {
    if (website) {
      return <BackgroundPhoto config={config} />
    }

    return <div className={styles.photo} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <Logo website={props.website} />
          <ClientSuspense fallback={<LoadingText lines={2} />}>{children}</ClientSuspense>
        </div>
        {renderMainLogo()}
      </div>
      {renderPhoto()}
    </div>
  )
}
