import React from 'react'
import {getWebsiteAuthInfo_config} from '@data/queries/website/__generated__/getWebsiteAuthInfo'

import styles from './styles.module.css'

export default function BackgroundPhoto(props: {config: getWebsiteAuthInfo_config}) {
  const {config} = props
  const url =
    (config.design && config.design.image && config.design.image.resizedURL) || '/background.jpeg'
  return <div className={styles.container} style={{backgroundImage: `url(${url})`}} />
}
