import getCustom from './getCustom'
import getEnv from './getEnv'

const urls = {
  local: `http://account.local:3010`,
  dev: 'https://account.bejusto.com',
  prod: 'https://account.getjusto.com'
}

const env = getEnv()

export default getCustom('account') || urls[env]
