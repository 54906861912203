import React, {useEffect, useState} from 'react'

export default function LoadingText({
  lineHeight = 5,
  lines = 1,
  widthPercentage = 100,
  useAnimationDelay = false,
  color = 'bg-gray-200 dark:bg-gray-800'
}) {
  const [animationDelay, setAnimationDelay] = useState(0)

  useEffect(() => {
    if (useAnimationDelay) setAnimationDelay(Math.random() * 0.5)
  }, [useAnimationDelay])

  return (
    <div>
      {Array.from({length: lines}, (_, key) => (
        <div
          style={{animationDelay: `${animationDelay}s`}}
          key={key}
          className={`rounded-sm my-1 h-${lineHeight} w-${widthPercentage}/100 ${color} animate-pulse`}></div>
      ))}
    </div>
  )
}
