import SsgFallback from '@components/SsgFallback'
import getWebsiteAuthInfo from '@data/queries/website/websiteAuthInfo'
import getWebsiteIdFromDomain from '@data/queries/website/websiteIdFromDomain'
import getWebsiteInitialData from '@data/queries/website/websiteInitialData.main'
import websiteSSRTranslations from '@helpers/misc/websiteSSRTranslations'
import AuthLayout from '@page-components/Auth/AuthLayout'
import ButtonLogin from '@page-components/Auth/ButtonLogin'
import AbstractLayout from '@page-components/Layout/AbstractLayout'
import Clean from '@page-components/Layout/Clean'
import {InferGetStaticPropsType} from 'next'
import {useRouter} from 'next/router'

export default function LoginPage(props: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  if (router.isFallback) {
    return <SsgFallback />
  }

  return (
    <AbstractLayout LayoutComponent={Clean} >
      <AuthLayout websiteId={props.websiteId} website={props.website} config={props.config}>
        <ButtonLogin />
      </AuthLayout>
    </AbstractLayout>
  )
}

export const getStaticProps = async context => {
  const websiteId = await getWebsiteIdFromDomain(context.params.hostname)
  const initialData = await getWebsiteInitialData(websiteId)
  const {website, config} = websiteId ? await getWebsiteAuthInfo(websiteId) : null

  return {
    props: {
      ...(await websiteSSRTranslations(context.params.hostname)),
      initialData,
      websiteId,
      website,
      config
    },
    revalidate: 60 * 60 * 24
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: true
  }
}
